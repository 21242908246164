$(document).ready(function() {
    var contentMarginTop = $('#content').outerHeight(true) - $('#content').outerHeight();

    $(window).scroll(function() {
        var headerJobthaiHeight = $('#header_jobthai').height();
        var navbarHeight = $('#navbar').outerHeight(true);
        var scroll = $(window).scrollTop();

        if (scroll >= headerJobthaiHeight) {
            $('#navbar').addClass('navbar-fixed-top');
            $('#content').css('margin-top', navbarHeight);
        } else {
            $('#navbar').removeClass('navbar-fixed-top');
            $('#content').css('margin-top', contentMarginTop);
        }
    });


    var toggles = document.querySelectorAll(".c-hamburger");

    for (var i = toggles.length - 1; i >= 0; i--) {
        var toggle = toggles[i];
        toggleHandler(toggle);
    };

    function toggleHandler(toggle) {
        toggle.addEventListener( "click", function(e) {
            e.preventDefault();
            (this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
        });
    }
});
